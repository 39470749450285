<template>
	<b-modal :title="scannerData.name" id="scanned-assets-view" ref="scanned-assets-view" name="scanned-assets-view"
		:no-close-on-backdrop="true" ok-only>
		<div lazy>
			<b-card class="mt-2">
				<div class="row">
					<div class="col">
						<b>Scanned Assets</b>
					</div>
					<div class="col-auto">
						<p class="card-text">
							Total:
							<span class="numFont">
							{{ scanAssetLogs ? scanAssetLogs.length : 0 }}
							</span>
						</p>
					</div>
				</div>
				<span class="caption"></span>
				<!-- For Input Scanned Assets -->
				<div class="mt-1">
					<div id="divAssetScan" ref="divAssetScan" class="truncate">
						<span :key="asset" v-for="asset in scanAssetLogs">
							&nbsp;
							<b-badge pill variant="primary" class="asset-pill">
								{{ asset }}
							</b-badge>
						</span>
					</div>
					<div v-show="isOverflown(scanAssetLogs)">
						<a class="see-more" ref="aAssetScan" href="#"
							@click.prevent="toggleAssetList('divAssetScan', 'aAssetScan')">
							See more
						</a>
					</div>
				</div>
			</b-card>

			<b-card class="mt-2">
				<div class="row">
					<div class="col">
						<b>Manually Inputted Assets</b>
					</div>
					<div class="col-auto">
						<p class="card-text">
							Total:
							<span class="numFont">
							{{ manualAssetLogs ? manualAssetLogs.length : 0 }}
							</span>
						</p>
					</div>
				</div>
				<span class="caption"></span>
				<!-- For Input Scanned Assets -->
				<div class="mt-1">
					<div id="divAssetManual" ref="divAssetManual" class="truncate">
						<span :key="asset" v-for="asset in manualAssetLogs">
							&nbsp;
							<b-badge pill variant="primary" class="asset-pill">
								{{ asset }}
							</b-badge>
						</span>
					</div>
					<div v-show="isOverflown(manualAssetLogs)">
						<a class="see-more" ref="aAssetManual" href="#"
							@click.prevent="toggleAssetList('divAssetManual', 'aAssetManual')">
							See more
						</a>
					</div>
				</div>
			</b-card>

			<b-card class="mt-2">
				<div class="row">
					<div class="col">
						<b>Auto-Added Assets</b>
					</div>
					<div class="col-auto">
						<p class="card-text">
							Total:
							<span class="numFont">
							{{ autoAssetLogs ? autoAssetLogs.length : 0 }}
							</span>
						</p>
					</div>
				</div>
				<span class="caption"></span>
				<!-- For Input Scanned Assets -->
				<div class="mt-1">
					<div id="divAssetAuto" ref="divAssetAuto" class="truncate">
						<span :key="asset" v-for="asset in autoAssetLogs">
							&nbsp;
							<b-badge pill variant="primary" class="asset-pill">
								{{ asset }}
							</b-badge>
						</span>
					</div>
					<div v-show="isOverflown(autoAssetLogs)">
						<a class="see-more" ref="aAssetAuto" href="#"
							@click.prevent="toggleAssetList('divAssetAuto', 'aAssetAuto')">
							See more
						</a>
					</div>
				</div>
			</b-card>
		</div>
	</b-modal>
</template>

<script>
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
	name: 'scanned-assets-view',
	data() {
		return {
			scannerData: {},
		};
	},
	computed: {
		manualAssetLogs() {
			if (_.has(this.scannerData.inputAssetLog, 'manual')) {
				if (this.scannerData.inputAssetLog.manual.length === 0) {
					return [];
				} else {
					return this.scannerData.inputAssetLog.manual;
				}
			} else {
				return [];
			}
		},
		scanAssetLogs() {
			if (_.has(this.scannerData.inputAssetLog, 'scan')) {
				if (this.scannerData.inputAssetLog.scan.length === 0) {
					return [];
				} else {
					return this.scannerData.inputAssetLog.scan;
				}
			} else {
				return [];
			}
		},
		autoAssetLogs() {
			if (_.has(this.scannerData.inputAssetLog, 'auto')) {
				if (this.scannerData.inputAssetLog.auto.length === 0) {
					return [];
				} else {
					return this.scannerData.inputAssetLog.auto;
				}
			} else {
				return [];
			}
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelScannedAssetsView', (scannerData) => {
			this.scannerData = scannerData;
		});
	},
	methods: {
		isOverflown(assetLogs) {
			let text = _.join(assetLogs, ' ');
			return text.length > 50;
		},
		toggleAssetList(divRef, aRef) {
			let elementArray = this.$refs[divRef];
			if (elementArray) {
				elementArray = elementArray.className;
				if (elementArray === 'truncate') {
					this.$refs[divRef].className = '';
					this.$refs[aRef].text = 'See less';
				} else {
					this.$refs[divRef].className = 'truncate';
					this.$refs[aRef].text = 'See more';
				}
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelScannedAssetsView');
	},
};
</script>

<style scoped>
.caption {
	font-style: italic !important;
	font-size: 10px !important;
}

.asset-pill {
	font-size: medium !important;
	font-family: monospace;
	margin-bottom: 4px;
}

.see-more {
	font-size: 14px !important;
}
</style>
