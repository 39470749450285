<template>
	<b-card>
		<b-tabs>
			<b-tab title="Primary Details" active>
				<b-card-text>
					<b-form-group label="Source" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<em>
							{{ sourceCompany }}
						</em>
						&nbsp;&nbsp;
						<strong>
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showDispatchLocation(row.item, 'source')" variant="dark" class="mr-1">
								<em class="fa fa-map-marker fa-lg"></em>
							</b-button>
						</strong>
						<span class="numFont">
							{{ getCoordinates(row.item.source.geoaddress) }}
						</span>
					</b-form-group>

					<b-form-group label="Destination" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<em>
							{{ destinationCompany }}
						</em>
						&nbsp;&nbsp;
						<strong>
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showDispatchLocation(row.item, 'destination')" variant="dark" class="mr-1">
								<em class="fa fa-map-marker fa-lg"></em>
							</b-button>
						</strong>
						<span class="numFont">
							{{ getCoordinates(row.item.destination.geoaddress) }}
						</span>
					</b-form-group>

					<b-form-group label="Remarks" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<div v-if="row.item.notes">
							<span class="truncate-text">
								<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
									type="html" :text="row.item.notes" />
							</span>
						</div>
					</b-form-group>

					<b-form-group label="Date Created" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ showFormattedDate(row.item.dateCreated) }}
					</b-form-group>
					<b-form-group label="Created By" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getUserDisplay(row.item.createdBy) }}
					</b-form-group>

					<b-form-group label="Date Updated" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ showFormattedDate(row.item.dateUpdated) }}
					</b-form-group>
					<b-form-group label="Updated By" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getUserDisplay(row.item.updatedBy) }}
					</b-form-group>

					<b-form-group label="Date Deployed" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right" v-show="row.item.status !== 'Draft' && row.item.status !== 'Cancelled'">
						{{ showFormattedDate(row.item.dateDeployed) }}
					</b-form-group>
					<b-form-group label="Deployed By" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right" v-show="row.item.status !== 'Draft' && row.item.status !== 'Cancelled'">
						{{ getUserDisplay(row.item.deployedBy) }}
					</b-form-group>

					<b-form-group v-show="row.item.status === 'Cancelled'" label="Date Cancelled" label-cols-sm="2"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ showFormattedDate(row.item.dateCancelled) }}
					</b-form-group>
					<b-form-group v-show="row.item.status === 'Cancelled'" label="Cancelled By" label-cols-sm="2"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ getUserDisplay(row.item.cancelledBy) }}
					</b-form-group>

					<b-form-group v-show="row.item.status === 'Received'" label="Date Received" label-cols-sm="2"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ showFormattedDate(row.item.dateReceived) }}
					</b-form-group>
					<b-form-group v-show="row.item.status === 'Received'" label="Received By" label-cols-sm="2"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ getUserDisplay(row.item.receivedBy) }}
					</b-form-group>
				</b-card-text>
			</b-tab>

			<b-tab title="Transportation">
				<b-card-text>
					<b-row class="mb-2">
						<b-col sm="7">
							<b-form-group label="Plate No/CR/OR" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<span class="numFont">
									{{ row.item.transportation.plateNo }}
								</span>
							</b-form-group>

							<b-form-group label="Company" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.transportation.company }}
							</b-form-group>

							<b-form-group label="Driver" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.driver.name }}
							</b-form-group>

							<b-form-group label="Assistant(s)" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.driver.assistants ? row.item.driver.assistants : '-' }}
							</b-form-group>
						</b-col>
						<b-col sm="3" v-show="hasDriversLicense(row.item.driver.licenseUrl)">
							<b-img v-img-orientation-changer :src="row.item.driver.licenseUrl" alt="Responsive image"
								thumbnail fluid />
							<a href="#" class="pull-left" @click.prevent="openDriversLicenseImage(row.item.driver)">
								Click to view full image
							</a>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

			<b-tab title="Source Scanners">
				<b-row class="mb-2">
					<b-col sm="3" class="text-sm-left">
						<b>Email</b>
					</b-col>
					<b-col sm="2" class="text-sm-left">
						<b>Name</b>
					</b-col>
					<b-col sm="2" class="text-sm-left">
						<b>Total Scanned</b>
					</b-col>
					<b-col sm="2" class="text-sm-center">
						<b>Actual Asset</b>
					</b-col>
				</b-row>
				<b-row class="mb-2" v-bind:key="scanner.id" v-for="scanner in row.item.sourceScanners">
					<b-col sm="3" class="text-sm-left">{{ scanner.id }}</b-col>
					<b-col sm="2" class="text-sm-left">{{ scanner.name }}</b-col>
					<b-col sm="2" class="text-sm-left numFont">{{
						scanner.scannedAssets.length
					}}</b-col>
					<b-col sm="2" class="text-sm-center">
						<b-button size="sm" @click="showScannedAssets(scanner)"
							v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" class="mr-1">
							<i class="fa fa-eye"></i>
						</b-button>
					</b-col>
				</b-row>
			</b-tab>

			<b-tab title="Destination Scanners" v-if="row.item.status === 'Receiving' || row.item.status === 'Received'">
				<b-row class="mb-2">
					<b-col sm="3" class="text-sm-left">
						<b>Email</b>
					</b-col>
					<b-col sm="2" class="text-sm-left">
						<b>Name</b>
					</b-col>
					<b-col sm="2" class="text-sm-left">
						<b>Total Scanned</b>
					</b-col>
					<b-col sm="2" class="text-sm-center">
						<b>Actual Asset</b>
					</b-col>
				</b-row>
				<b-row class="mb-2" v-bind:key="scanner.id" v-for="scanner in row.item.destinationScanners">
					<b-col sm="3" class="text-sm-left">{{ scanner.id }}</b-col>
					<b-col sm="2" class="text-sm-left">{{ scanner.name }}</b-col>
					<b-col sm="2" class="text-sm-left numFont">{{
						scanner.scannedAssets.length
					}}</b-col>
					<b-col sm="2" class="text-sm-center">
						<b-button size="sm" @click="showScannedAssets(scanner)"
							v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" class="mr-1">
							<i class="fa fa-eye"></i>
						</b-button>
					</b-col>
				</b-row>
			</b-tab>

			<b-tab title="Assets">
				<DispatchDetailsAssetView :dispatch="dispatch" />
			</b-tab>

			<b-tab title="Proof of Receipt" v-if="row.item.toInactiveNode == 'true' && row.item.proofOfReceipt != null">
				<b-row class="mb-2">
					<b-col sm="3">
						<b-img v-img-orientation-changer :src="row.item.proofOfReceipt.imgUrl" alt="Responsive image"
							thumbnail fluid />
						<a href="#" class="pull-right" @click.prevent="openImage(row.item.proofOfReceipt.imgUrl)">
							Click to view full image
						</a>
					</b-col>
					<b-col sm="9">
						<b-row v-if="geoaddress" class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Coordinates:</b>
							</b-col>
							<b-col>
								<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'" @click.stop="
									showCapturedImageLocation(
										row.item.proofOfReceipt.geoaddress
									)
									" variant="dark" class="mr-1">
									<i class="fa fa-map-marker fa-lg"></i>
								</b-button>

								{{ getCoordinates(row.item.proofOfReceipt.geoaddress) }}
							</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Recipient Name:</b>
							</b-col>
							<b-col>{{
								getDisplayValue(row.item.proofOfReceipt.recipientName)
							}}</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Approved By:</b>
							</b-col>
							<b-col>{{
								getDisplayValue(row.item.proofOfReceipt.approvedBy)
							}}</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Date Approved:</b>
							</b-col>
							<b-col>{{
								showFormattedDate(row.item.proofOfReceipt.dateApproved)
							}}</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Notes:</b>
							</b-col>
							<b-col sm="4">
								<div v-if="row.item.proofOfReceipt.notes">
									<span class="truncate-text">
										<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100"
											less="Show Less" type="html" :text="row.item.proofOfReceipt.notes" />
									</span>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>

	</b-card>
</template>

<script>
// Component
import DispatchDetailsAssetView from './DispatchDetailsAssetView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { UserUtil } from '@/utils/userutil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'dispatch-details-view',
	components: {
		DispatchDetailsAssetView,
		truncate,
		Loading,
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			scanner: {}
		};
	},
	computed: {
		dispatch() {
			return this.row.item;
		},
		sourceCompany() {
			return DispatchUtil.getCompanyLocationDisplay(this.dispatch.source);
		},
		destinationCompany() {
			return DispatchUtil.getCompanyLocationDisplay(this.dispatch.destination);
		},
		geoaddress() {
			if (this.dispatch.proofOfReceipt)
				return this.dispatch.proofOfReceipt.geoaddress;
			else return {};
		}
	},
	methods: {
		getUserDisplay(userId) {
			return UserUtil.getUserDisplay(this.allUsersObj, userId);
		},

		getCoordinates(geoaddress) {
			geoaddress = LocationUtil.getGeoaddress(geoaddress);

			return (
				'(' +
				geoaddress.latitude.toFixed(6) +
				', ' +
				geoaddress.longitude.toFixed(6) +
				')'
			);
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getDisplayValue(value) {
			if (!value || value.length === 0) {
				return '-';
			}
			return value;
		},
		showScannedAssets(scanner) {
			this.scanner = scanner;

			let selDispatch = this.dispatch;
			let sourceScanners = selDispatch.sourceScanners;
			let destinationScanners = selDispatch.destinationScanners;

			let inputAssetLog = {};

			if (sourceScanners.indexOf(scanner) > -1) {
				inputAssetLog = selDispatch.inputAssetLog.dispatch;
			} else if (destinationScanners.indexOf(scanner) > -1) {
				inputAssetLog = selDispatch.inputAssetLog.receipt;
			}

			let scan = [];
			let manual = [];
			let auto = [];

			if (Object.keys(inputAssetLog).length !== 0) {
				this.scanner.scannedAssets.forEach((asset) => {
					if (inputAssetLog.scan) {
						for (const assetScanned of inputAssetLog.scan) {
							if (asset === assetScanned) {
								scan.push(assetScanned);
								break;
							}
						}
					}
					if (inputAssetLog.manual) {
						for (const assetManual of inputAssetLog.manual) {
							if (asset === assetManual) {
								manual.push(assetManual);
								break;
							}
						}
					}
					if (inputAssetLog.auto) {
						for (const autoAddedAssets of inputAssetLog.auto) {
							if (asset === autoAddedAssets) {
								auto.push(autoAddedAssets);
								break;
							}
						}
					}
				});
			}

			this.scanner.inputAssetLog = {
				scan,
				manual,
				auto,
			};

			EventBus.$emit('onUpdateSelScannedAssetsView', this.scanner);
			this.$bvModal.show('scanned-assets-view');
		},
		showDispatchLocation(details, endpoint) {
			const params = {
				source: details.source,
				destination: details.destination,
				endpoint,
			};

			EventBus.$emit('onUpdateDispatchLocationView', params);
			this.$bvModal.show('dispatch-location-view');
		},
		openImage(imageLink) {
			EventBus.$emit('onUpdateSelProofReceiptImageView', {
				dispatchId: this.dispatch.dispatchId,
				imageLink: imageLink,
				latitude: this.geoaddress.latitude ? this.geoaddress.latitude : 0.0,
				longitude: this.geoaddress.longitude ? this.geoaddress.latitude : 0.0,
				dateApproved: this.dispatch.proofOfReceipt
					? this.dispatch.proofOfReceipt.dateApproved
					: '',
			});
			this.$bvModal.show('receipt-image-view');
		},

		showCapturedImageLocation() {
			EventBus.$emit(
				'onUpdateSelProofReceiptImageLocationView',
				this.geoaddress
			);
			this.$bvModal.show('proof-receipt-image-location-view');
		},
		toggleNotes(divRefId, aRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let className = elementArray[0].className;
					if (className === 'truncate') {
						this.$refs[divRefId][0].className = null;
						this.$refs[aRefId][0].text = 'See less';
					} else {
						this.$refs[divRefId][0].className = 'truncate';
						this.$refs[aRefId][0].text = 'See more';
					}
				} else {
					let className = elementArray.className;
					if (className === 'truncate') {
						this.$refs[divRefId].className = null;
						this.$refs[aRefId].text = 'See less';
					} else {
						this.$refs[divRefId].className = 'truncate';
						this.$refs[aRefId].text = 'See more';
					}
				}
			}
		},

		openDriversLicenseImage(driver) {
			EventBus.$emit('onSelDriversLicenseImageView', {
				driver: driver,
			});
			this.$bvModal.show('driver-license-image-view');
		},
		hasDriversLicense(licenseUrl) {
			return licenseUrl && !_.isEmpty(licenseUrl);
		},
	},
};
</script>