<template>
	<b-modal
		title="Proof of Receipt Image Location"
		id="proof-receipt-image-location-view"
		:no-close-on-backdrop="true"
		@shown="refreshMap"
		size="lg"
		ok-only
		centered
	>
		<b-row>
			<b-col sm="12">
				<GmapMap
					:center="coordinates"
					:zoom="12"
					:style="{ width: '100%', height: '70vh' }"
				>
					<GmapMarker :position="coordinates" />
				</GmapMap>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import EventBus from '@/shared/event-bus';
export default {
	name: 'storage-location-view',
	data() {
		return {
			geoaddress: {},
			infoMarkerToggle: false,
		};
	},
	computed: {
		coordinates() {
			const coordinates = this.geoaddress;
			if (coordinates) {
				return {
					lat: coordinates._latitude,
					lng: coordinates._longitude,
				};
			} else return {};
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelProofReceiptImageLocationView', (geoaddress) => {
			this.geoaddress = geoaddress;
		});
	},
	methods: {
		refreshMap() {
			this.$gmapDefaultResizeBus.$emit('resize');
			this.infoMarkerToggle = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelProofReceiptImageLocationView');
	},
};
</script>