<template>
	<b-modal id="receive-dispatch-with-1up1down" ref="receive-dispatch-with-1up1down" :title="title" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<div class="confirm-message">
			<b-row>
				<b-col sm="12" class="mb-1">
					<span>
						You have some
						<b>unmatched</b> asset(s):
					</span>
				</b-col>

				<b-col sm="12" class="mb-1" v-if="unmatchedAssets.length > 0">
					<span v-bind:key="asset.assetCode" v-for="asset in unmatchedAssets">
						<b-badge pill variant="primary">{{ asset.assetCode }}</b-badge>
						&nbsp;
					</span>
				</b-col>

				<b-col sm="12">
					<hr />
				</b-col>
				<b-col sm="12" class="mt-1">
					Proceed receiving
					<b>{{ selDispatch.dispatchId }}</b> for <b>{{ recipient }}</b>? &nbsp;
					<br />
					<i>(Specify date received then choose option below)</i>
				</b-col>
			</b-row>

			<b-row class="my-3">
				<b-col sm="6">
					<b-form-group label="Date Received">
						<date-range-picker opens="center" :single-date-picker="true" :timePicker="true"
							:timePicker24Hour="false" v-model="dateReceived" applyLabel="Apply" cancelLabel="Cancel"
							:style="{ width: '100%' }">
							<div slot="input">{{ dateReceivedDisplay }}</div>
						</date-range-picker>
					</b-form-group>
				</b-col>
			</b-row>
		</div>
		<template v-slot:modal-footer="{ cancel }">
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
			<b-button variant="secondary" @click="cancel()">Cancel</b-button>
			<b-button variant="primary" @click="proceedReceiving">Proceed</b-button>
		</template>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '../../../utils/userutil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'receive-dispatch-with-1up1down',
	components: {
		Loading,
		DateRangePicker,
	},
	props: {
		allUsersObj: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			selDispatch: {},
			unmatchedAssets: [],
			recipient: '',
			loggedUser: this.$store.getters.loggedUser,
			dateReceived: DateUtil.getCurrentTimestamp(),

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Receive Dispatch w/ 1 Up 1 Down';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		dateReceivedDisplay: {
			get() {
				return moment(this.dateReceived.startDate).format(
					'MMMM D YYYY, h:mm a'
				);
			},
			set(value) {
				this.dateReceived = value.startDate;
			},
		},
		timeStampedReceivedDate() {
			return moment(this.dateReceived.startDate).valueOf();
		},
	},
	mounted() {
		EventBus.$on('onReceiveDispatchWith1Up1Down', (param) => {
			this.selDispatch = param[0];
			this.unmatchedAssets = param[1];
			this.recipient = this.selDispatch.destination.company;

			if (this.$refs['receive-dispatch-with-1up1down']) {
				this.$refs['receive-dispatch-with-1up1down'].show();
			}
		});
	},
	methods: {
		async proceedReceiving() {
			// show loading indicator
			this.isLoading = true;

			// set the actual assets to have the expected assets for now
			// TODO implement a UI for receiving assets based on the actual assets received
			this.selDispatch.actualAssets = this.selDispatch.assets;

			// Auto-apply 1 up 1 down, once detected during validate receive
			this.selDispatch.apply1Up1Down = true;

			// Update the scannedAssets field in destinationScanners
			this.selDispatch.destinationScanners = UserUtil.getDefaultScanners(this.allUsersObj, this.loggedUserCompany.id);
			let destinationScanners = this.selDispatch.destinationScanners
			let scanner = _.find(destinationScanners, (o) => {
				return o.id === this.loggedUser.id;
			});
			scanner.scannedAssets = this.selDispatch.actualAssets;

			//Populate auto field with received assets
			this.selDispatch.inputAssetLog.receipt.auto = this.selDispatch.actualAssets;

			//Update remarks
			this.selDispatch.notes = this.selDispatch.notes + "; " +
				`Dispatch ${this.selDispatch.dispatchId} was received by ${this.selDispatch.source.company} User via CMS. 
                All assets are auto-received, no scanning was conducted`;

			let dispatchId = this.selDispatch.dispatchId;
			try {
				let { data } = await dispatchApi.receiveDispatch(
					this.selDispatch,
					this.loggedUser.id,
					this.timeStampedReceivedDate
				);

				if (data.isSuccess) {
					this.$toaster.success(`Dispatch "${this.selDispatch.dispatchId}" was received successfully.`);
					this.$refs['receive-dispatch-with-1up1down'].hide();
					EventBus.$emit('onCloseReceiveDispatch', data.dispatch);
				} else {
					this.$toaster.error(data.message);
				}
			} catch (_error) {
				this.$toaster.error(`Error receiving dispatch ${dispatchId}. Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			this.dateReceived = DateUtil.getCurrentTimestamp();
		},
	},
	beforeDestroy() {
		EventBus.$off('onReceiveDispatchWith1Up1Down');
	},
};
</script>

