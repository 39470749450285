<template>
	<div>
		<b-row class="pb-2">
			<b-col>
				<b>Assign Location</b>
			</b-col>
		</b-row>
		<b-row class="pb-1">
			<b-col md="9" class="text-left">
				<b>Your Address:</b> {{ displayAddress }} <br />
				<b>Geoaddress:</b> <i class="icon-location-pin"></i>&nbsp; ({{
					displayCoordinates.lat
				}}, {{ displayCoordinates.lng }})
			</b-col>
			<b-col md="3" class="text-right">
				<button class="btn btn-primary mr-2" @click.prevent="getCurrentLocation">
					Reset
				</button>
				<button class="btn btn-primary" @click.prevent="getStreetAddressFrom(location)">
					Apply
				</button>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<gmap-map v-if="defaultLocation" :center="defaultLocation" :zoom="12"
					:style="{ width: '100%', height: '50vh' }">
					<gmap-marker :position="defaultLocation" :draggable="true" @drag="onMarkerDrop">
						<gmap-info-window :opened="address !== null ? true : false">
							<b>Address:</b> {{ displayAddress }} <br />
							<b>Coordinates:</b> <i class="icon-location-pin"></i>&nbsp; ({{
								displayCoordinates.lat
							}}, {{ displayCoordinates.lng }})
						</gmap-info-window>
					</gmap-marker>
				</gmap-map>
				<span class="text-muted">
					<i class="fa fa-exclamation-circle"></i>&nbsp; Drag pin marker to your
					designated location and press apply button to get address and
					coordinates.
				</span>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name: 'google-map-picker',
	data() {
		return {
			defaultLocation: null,
			location: null,
			address: null,
		};
	},
	computed: {
		displayAddress() {
			if (this.address === null) {
				return '';
			}

			return this.address.formatted_address;
		},
		displayCoordinates() {
			const loc = {
				lat: 0.0,
				lng: 0.0,
			};

			if (this.location === null) {
				return loc;
			}

			loc.lat = parseFloat(this.location.lat).toFixed(6);
			loc.lng = parseFloat(this.location.lng).toFixed(6);
			return loc;
		},
	},
	async mounted() {
		await this.getCurrentLocation();
	},
	methods: {
		async getCurrentLocation() {
			try {
				const location = await this.$getCurrentLocation();
				this.defaultLocation = location;
				this.location = location;

				setTimeout(() => {
					this.getStreetAddressFrom(location);
				}, 1000);
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}
		},
		onMarkerDrop(location) {
			this.address = null;
			this.location = {
				lat: location.latLng.lat(),
				lng: location.latLng.lng(),
			};

			this.$gmapDefaultResizeBus.$emit('resize');
		},
		async getStreetAddressFrom(location) {
			this.address = null;
			try {
				this.address = await this.$geocoder(location);
				this.$emit('onPickedLocation', {
					...location,
					address: this.address.formatted_address,
				});
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}
		},
	},
};
</script>