<template>
    <b-modal id="confirm-print-dispatch" size="lg" :title="title" ok-title="Download" ref="modal" @ok="onConfirm"
        :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-row class="my-2 ml-2">
            <b-col sm="12">
                <b-form-group label="No. of Copies" label-class="font-weight-bold pt-0">
                    <b-form-radio-group v-model="type" :options="copiesOptions" name="noOfCopies" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="my-2 ml-2" v-show="type === 'Multiple Copies'">
            <b-col sm="12">
                <b-form-group label="Additional Copy Owners" label-class="font-weight-bold pt-0">
                    <b-row class="mb-2 border-bottom-1" v-for="(owner, key) in ownersOptions" :key="key" no-gutters>
                        <b-col sm="2" class="text-sm-center mr-1 p-2">
                            Copy {{ key + 1 }}
                        </b-col>
                        <b-col sm="6" class="text-sm-right mr-1">
                            <b-form-group>
                                <b-form-input name="Name" v-model="owner.name" placeholder="Name" maxlength="25" v-validate="{
                                    required: owner.isIncluded
                                }" />
                                <span v-show="errors.has('Name')" class="help-block">
                                    {{ errors.first('Name') }}
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3" class="text-sm-left mr-1 p-2">
                            <b-form-checkbox v-model="owner.isIncluded" name="isIncluded" :value="true"
                                :unchecked-value="false">
                                <span class="is-included">{{ owner.isIncluded ? 'Included' : 'Not Included' }} </span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>
        </b-row>

    </b-modal>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'confirm-print-dispatch',
    components: {
        Loading,
    },
    props: {
        selDispatch: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            type: 'Single Copy',
            copies: ['Manager', 'Client', 'Driver'],

            copiesOptions: ['Single Copy', 'Multiple Copies'],
            ownersOptions: [],

            isLoading: false,
        }
    },
    computed: {
        title() {
            return 'Print Dispatch ' + this.selDispatch.dispatchId;
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
    },
    mounted() {
        // reset
        this.type = 'Single Copy';
        this.ownersOptions = [
            {
                name: 'Manager',
                isIncluded: true
            },
            {
                name: 'Client',
                isIncluded: true
            },
            {
                name: 'Driver',
                isIncluded: true
            }
        ];
        this.isLoading = false;
    },
    methods: {
        onConfirm(event) {
            event.preventDefault();

            this.isLoading = true;

            let includedCopies = _.filter(this.ownersOptions, o => {
                return o.isIncluded;
            });

            if (_.isEmpty(includedCopies)) {
                this.$toaster.warning('Please select at least 1 copy owner to include.');
                this.isLoading = false;
            } else {
                this.copies = _.map(includedCopies, 'name');
                this.$emit('onConfirmPrintDispatch', { type: this.type, copies: this.copies });
                this.$bvModal.hide('confirm-print-dispatch');
            }
        }
    }
}
</script>

<style scoped>
.is-included {
    font-size: 15px;
    font-style: italic;
}
</style>