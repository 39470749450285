<template>
	<div id="imageUpload">
		<input type="file" @change="onFileChange" id="uploadInput" />
		<div class="Image-input__image-wrapper">
			<i v-show="!url" class="icon fa fa-picture-o"></i>
			<img v-show="url" class="Image-input__image" :src="url" alt="Uploaded Image" />
		</div>
	</div>
</template>

<script>
import { ImageUtil } from '@/utils/imageUtil.js';
import EventBus from '@/shared/event-bus';

export default {
	name: 'ImageUpload',

	props: {
		folderName: {
			required: true,
		},
		image: {
			required: false,
		},
		imageFileName: {
			required: false,
		},
	},

	data() {
		return {
			filename: '',
			url: '',
		};
	},

	mounted() {
		// initialize value from properties
		this.url = this.image;
		this.filename = this.imageFileName;

		EventBus.$on('onResetImageUploadField', () => {
			this.resetInputField();
		});
	},

	methods: {
		onFileChange(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) {
				return;
			}
			let file = files[0];
			if (
				ImageUtil.validateFileExtension(file) &&
				ImageUtil.validateFileSize(file)
			) {
				// render image to the browser
				this.previewThumbnail(file);
				this.$emit('onImageChange', [file]);
			} else {
				this.resetInputField();
			}
		},

		resetInputField() {
			// reset input field
			document.getElementById('uploadInput').value = '';
			this.filename = '';
			this.url = '';
		},

		previewThumbnail(file) {
			let reader = new FileReader();
			reader.onload = (e) => {
				this.url = e.target.result;
			};
			reader.readAsDataURL(file);
		},
	},

	watch: {
		image: {
			handler() {
				this.url = this.image;
			},
			deep: true,
		},
	},

	beforeDestroy() {
		EventBus.$off('onResetImageUploadField');
	},
};
</script>

<style scoped>
.Image-input__image-wrapper {
	flex: 1;
	height: 400px;
	width: 100%;
	border-radius: 1px;
	margin-right: 10px;
	overflow-y: hidden;
	border-radius: 1px;
	background: #eee;
	justify-content: center;
	align-items: center;
	display: flex;
}

.Image-input__image-wrapper>.icon {
	color: #ccc;
	font-size: 50px;
	cursor: default;
}

.Image-input__image {
	max-width: 100%;
	min-width: 100%;
	border-radius: 1px;
}
</style>
