<template>
	<div>
		<span v-if="row.item.status == 'Draft'">
			<b-badge variant="secondary">{{ row.item.status }}</b-badge>
		</span>
		<span v-if="row.item.status == 'Receiving'">
			<b-badge variant="dark">{{ row.item.status }}</b-badge>
		</span>
		<span v-if="row.item.status == 'Received'">
			<b-badge variant="primary">{{ row.item.status }}</b-badge>
		</span>
		<span v-if="row.item.status == 'In-Transit'">
			<b-badge variant="success">{{ row.item.status }}</b-badge>
		</span>
		<span v-if="row.item.status == 'Cancelled'">
			<b-badge variant="danger">{{ row.item.status }}</b-badge>
		</span>
		<span v-if="row.item.status == 'Pending'">
			<b-badge variant="info">{{ row.item.status }}</b-badge>
		</span>
	</div>
</template>

<script>
export default {
	name: 'dispatch-row-status',
	props: {
		row: {
			type: Object,
			required: true
		}
	}
};
</script>

<style>
</style>

