<template>
	<b-modal v-model="show" id="print-dispatch-summary" :title="title" size="xl" ref="modal"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container v-show="selDispatch !== null">

			<div v-for="(copyOwner, key) in copies " :key="key">
				<div v-for="page in totalPage" :key="page">
					<div class="page-container" :id="getKey(copyOwner, page - 1)" v-show="page - 1 === currPage"
						v-if="page - 1 === 0">
						<!-- Header -->
						<DispatchSummaryHeader :selDispatch="selDispatch" />

						<!-- Source and Destination -->
						<b-row class="px-2 mb-2">
							<b-col sm="6" class="border border-light border-1 p-2">
								<div class="section-header">Source</div>
								<!-- Company -->
								<div class="form-field">Company: <span class="form-value-uppercase">{{
									sourceCompanyName
								}}</span>
								</div>
								<!-- Warehouse -->
								<div class="form-field">Warehouse: <span class="form-value-uppercase">{{
									sourceLocationName
								}}</span>
								</div>
								<!-- Address -->
								<div class="form-field">Address: <span class="form-value-uppercase">{{
									sourceLocation && sourceLocation.address ? sourceLocation.address : '-'
								}}</span>
								</div>
								<!-- Date Deployed -->
								<div class="form-field">Date Dispatched: <span class="form-value-uppercase">{{
									selDispatch.dateDeployed ? showFormattedDate(selDispatch.dateDeployed) : '-'
								}}</span>
								</div>
							</b-col>

							<b-col sm="6" class="border border-light border-1 p-2">
								<div class="section-header">Destination</div>
								<!-- Company -->
								<div class="form-field">Company: <span class="form-value-uppercase">{{
									destinationCompanyName
								}}</span>
								</div>
								<!-- Warehouse -->
								<div class="form-field">Warehouse: <span class="form-value-uppercase">{{
									destinationLocationName
								}}</span>
								</div>
								<!-- Address -->
								<div class="form-field">Address: <span class="form-value-uppercase">{{
									destinationLocation && destinationLocation.address ? destinationLocation.address : "-"
								}}</span>
								</div>
								<!-- Date Received -->
								<div class="form-field">Date Received: <span class="form-value-uppercase">{{
									selDispatch.dateReceived ? showFormattedDate(selDispatch.dateReceived) : '-'
								}}</span>
								</div>
							</b-col>
						</b-row>

						<!-- Asset Types -->
						<DispatchSummaryAssetTypes :selDispatch="selDispatch" :assetsObj="assetsObj" />

						<!-- Remarks -->
						<b-row class="px-2 mt-2 mb-4 remarks-section">
							<b-col sm="12" class="border border-light border-1 p-2">
								<span class="section-header">REMARKS: </span>
								<span class="remarks-value" v-html="remarks"></span>
							</b-col>
						</b-row>

						<!-- Signatories -->
						<DispatchSummarySignatories :selDispatch="selDispatch" :deployedByUser="deployedByUser"
							:receivedByUser="receivedByUser" />

						<!-- Footer -->
						<DispatchSummaryFooter :currPage="page - 1" :totalPage="totalPage" :copyOwner="copyOwner" />
					</div>

					<div class="page-container" :id="getKey(copyOwner, page - 1)" v-show="page - 1 === currPage" v-else>
						<!-- Header -->
						<DispatchSummaryHeader :selDispatch="selDispatch" />

						<!-- Asset Listing Here -->
						<DispatchSummaryAssets :selDispatch="selDispatch" :assetsObj="getAssetsObj(page - 1)" />

						<!-- Signatories -->
						<DispatchSummarySignatories :selDispatch="selDispatch" :deployedByUser="deployedByUser"
							:receivedByUser="receivedByUser" />

						<!-- Footer -->
						<DispatchSummaryFooter :currPage="page - 1" :totalPage="totalPage" :copyOwner="copyOwner" />
					</div>
				</div>
			</div>

		</b-container>

		<template #modal-footer>
			<div class="w-100">
				<span class="float-left">
					<b-button variant="success" @click="prevPage" class="footer-button">
						Back
					</b-button>
					<b-button variant="success" @click="nextPage" class="footer-button">
						Next
					</b-button>
				</span>

				<span class="pagination-status" v-show="totalPage > 1">
					Page <strong>{{ currPage + 1 }}</strong> of <strong>{{ totalPage }}</strong>
				</span>

				<span class="float-right">
					<b-button variant="secondary" @click="show = false" class="footer-button">
						Close
					</b-button>
					<b-button variant="primary" @click="confirmPrintDispatch" class="footer-button">
						Print
					</b-button>
				</span>
			</div>
		</template>

		<ConfirmPrintDispatch :selDispatch="selDispatch" @onConfirmPrintDispatch="printDispatch" />
	</b-modal>
</template>

<script>
// Components
import DispatchSummaryHeader from './summary/DispatchSummaryHeader';
import DispatchSummaryAssetTypes from './summary/DispatchSummaryAssetTypes';
import DispatchSummaryAssets from './summary/DispatchSummaryAssets';
import DispatchSummarySignatories from './summary/DispatchSummarySignatories';
import DispatchSummaryFooter from './summary/DispatchSummaryFooter';
import ConfirmPrintDispatch from './summary/ConfirmPrintDispatch';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// DAO
import assetsDAO from '@/database/assets';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';

export default {
	name: 'print-dispatch-summary',
	components: {
		DispatchSummaryHeader,
		DispatchSummaryAssetTypes,
		DispatchSummaryAssets,
		DispatchSummarySignatories,
		DispatchSummaryFooter,
		ConfirmPrintDispatch,
		Loading,
	},
	props: {
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			show: false,
			selDispatch: {},
			sourceLocation: {},
			destinationLocation: {},
			deployedByUser: '',
			receivedByUser: '',
			assetsObj: {},

			copies: ['Original'],
			currPage: 0,
			configTimeout: 5000,
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Print Dispatch ' + this.selDispatch.dispatchId;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		dispatchedAssets() {
			return this.selDispatch['Dispatched Assets'] ? this.selDispatch['Dispatched Assets'].length : 0;
		},
		actualAssets() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			return dispatch.actualAssets ? dispatch.actualAssets.length : 0;
		},
		remarks() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			return dispatch.notes ? this.truncateRemarks(dispatch.notes) : '-';
		},
		dispatchId() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			return dispatch.dispatchId ? dispatch.dispatchId : "-";
		},
		sourceCompanyName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let source = dispatch.source ? dispatch.source : {};
			return source && source.company && source.company.length > 0 ? source.company : '-'
		},
		sourceLocationName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let source = dispatch.source ? dispatch.source : {};
			return source && source.storageLocation && source.storageLocation.length > 0 ? source.storageLocation : '-'
		},
		destinationCompanyName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let destination = dispatch.destination ? dispatch.destination : {};
			return destination && destination.company && destination.company.length > 0 ? destination.company : '-'
		},
		destinationLocationName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let destination = dispatch.destination ? dispatch.destination : {};
			return destination && destination.storageLocation && destination.storageLocation.length > 0 ? destination.storageLocation : '-'
		},
		totalPage() {
			let totalAssets = _.size(this.assetsObj);
			return _.ceil(totalAssets / 20) + 1;
		},
		assetsBy20() {
			return _.chunk(Object.values(this.assetsObj), 20);
		}
	},
	mounted() {
		EventBus.$on('onPrintDispatch', async (selDispatch) => {
			this.onReset();

			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
				await this.retrieveOtherDetails(selDispatch);
			}

			this.$bvModal.show('print-dispatch-summary');
		});
	},
	methods: {
		async retrieveOtherDetails(selDispatch) {
			// Show loader
			this.isLoading = true;

			let sourceLocId = selDispatch.source.storageLocationId;
			this.sourceLocation = this.allStorageLocationsObj[sourceLocId];

			let destinationLocId = selDispatch.destination.storageLocationId;
			this.destinationLocation = this.allStorageLocationsObj[destinationLocId];

			if (selDispatch.deployedBy && selDispatch.deployedBy.length > 0) {
				this.deployedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.deployedBy]);
			} else {
				this.deployedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.createdBy]);
			}
			
			if (selDispatch.receivedBy && selDispatch.receivedBy.length > 0) {
				this.receivedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.receivedBy]);
			}

			let assets = this.selDispatch.assets ? this.selDispatch.assets : [];
			let actualAssets = this.selDispatch.actualAssets ? this.selDispatch.actualAssets : [];
			let assetCodes = _.uniq([...assets, ...actualAssets]);
			let assetsObjResult = await assetsDAO.getAssetsByCode(assetCodes);
			this.assetsObj = assetsObjResult[0];

			// Check for loader
			this.isLoading = false;
		},
		

		confirmPrintDispatch(event) {
			event.preventDefault();
			this.$bvModal.show('confirm-print-dispatch');
		},
		async printDispatch(param) {
			try {
				// show loading indicator
				this.isLoading = true;

				let type = param.type;
				if (type === 'Single Copy') {
					this.copies = ['Original'];
				} else {
					this.copies = ['Original', ...param.copies];
				}

				setTimeout(async () => {
					await this.downloadMultipleCopies(this.copies, this.totalPage);
					this.$refs.modal.hide();
					this.onReset();
				}, this.configTimeout);

			} catch (error) {
				console.error('Error occurred:', error);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		// Multiple Copies
		async downloadMultipleCopies(copies, totalPage) {
			let imageElements = [];
			for (let i = 0; i < copies.length; i++) {
				let copyOwner = copies[i];
				for (let j = 0; j < totalPage; j++) {
					let id = this.getKey(copyOwner, j);
					const containerElement = document.getElementById(id);
					imageElements.push({
						element: containerElement,
						copyOwner: copyOwner,
						key: j
					});
				}
			}

			const zip = new JSZip();
			const promises = [];

			_.forEach(imageElements, imageElement => {
				let element = imageElement.element;
				let copyOwner = imageElement.copyOwner;
				let key = imageElement.key;

				let filename = this.getFileName(copyOwner, key);
				promises.push(this.captureAndAddToZip(element, zip, filename));
			});
			await Promise.all(promises);

			const content = await zip.generateAsync({ type: 'blob' });

			let zipFilename = this.dispatchId + '.zip';
			saveAs(content, zipFilename);
		},
		async captureAndAddToZip(element, zip, filename) {
			// Make the element temporarily visible
			const originalDisplay = element.style.display;
			element.style.display = 'block';

			// Add a delay before capturing the image
			await new Promise(resolve => setTimeout(resolve, this.configTimeout));
			const canvas = await html2canvas(element);

			// Restore the original display property
			element.style.display = originalDisplay;

			return new Promise((resolve) => {
				canvas.toBlob((blob) => {
					zip.file(filename, blob);
					resolve();
				});
			});
		},

		getKey(copyOwner, key) {
			return 'dispatch-summary-' + copyOwner.toLowerCase() + '-' + key;
		},
		getFileName(copyOwner, key) {
			let currPage = key + 1;
			if (this.totalPage > 1) {
				return this.dispatchId + '_' + copyOwner + '_Copy' + currPage + '_of_' + this.totalPage + '.png';
			} else {
				return this.dispatchId + '_' + copyOwner + '_Copy.png';
			}
		},

		onReset() {
			this.selDispatch = {};
			this.sourceLocation = {};
			this.destinationLocation = {};
			this.deployedByUser = '';
			this.receivedByUser = '';
			this.assetsObj = {};

			this.copies = ['Original'];
			this.currPage = 0;

			// Check for loader
			this.isLoading = false;
			this.show = true;
		},

		prevPage() {
			if (this.currPage > 0) {
				this.currPage = this.currPage - 1;
			}
		},
		nextPage() {
			if (this.currPage < this.totalPage - 1) {
				this.currPage = this.currPage + 1;
			}
		},
		getAssetsObj(key) {
			let index = key - 1;
			let assetsArr = this.assetsBy20[index];
			if (!_.isEmpty(assetsArr)) {
				return _.keyBy(assetsArr, 'assetCode');
			}
			return {};
		},

		// UTILS
		getNameDisplay(userObj) {
			return userObj ? userObj.firstName + ' ' + userObj.lastName : 'TAWItech Support';
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		truncateRemarks(string) {
			if (string.length > 200) {
				let remarks = string.substring(0, 200) + '...';
				return this.breakRemarks(remarks, 148);
			}
			return this.breakRemarks(string, 148);
		},
		breakRemarks(remarks, length) {
			return remarks.length > length ? remarks.replace(new RegExp(`([^\\s]{${length}})`, 'g'), '$1<br>') : remarks;
		}
	},
	beforeDestroy() {
		EventBus.$off('onPrintDispatch');
	},
};
</script>

<style scoped>
.page-container {
	padding-top: 1em;
	padding-left: 1.75em;
	padding-right: 1.75em;
	position: relative;
    max-width: 100wh;
	aspect-ratio: 13 / 9;  
	overflow: visible;
}

.section-header {
	text-transform: uppercase;
	color: #122C91;
	font-size: small;
	font-style: normal;
	font-weight: bold;
	line-height: normal;
}

.form-field {
	font-size: small;
	text-align: left;
	text-transform: uppercase;
	color: #4A4A4A;
	font-weight: 400;
	line-height: normal;
}

.form-value {
	font-size: small;
	font-weight: bold;
	text-align: left;
	text-transform: uppercase;
	line-height: small;
	color: #484554;
}

.form-value-uppercase {
	font-size: small;
	text-align: left;
	text-transform: uppercase;
	color: #484554;
	font-weight: 700;
	line-height: normal;
}

.remarks-section {
	height: 7em;
}

.remarks-value {
	font-size: small;
}

.page-container {
	padding-top: 1em;
	padding-left: 1.75em;
	padding-right: 1.75em;
	position: relative;
    max-width: 100wh;
	aspect-ratio: 13 / 9;  
	overflow: visible;
}

.footer-button {
	margin-left: 0.3em;
}

.pagination-status {
	padding: 0.7em;
	text-transform: uppercase;
	vertical-align: middle;
}
</style>
