<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" @click.stop="row.toggleDetails"
			class="mr-1 mt-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>

		<b-button v-show="status === 'Draft' && !isViewer" size="sm" v-b-tooltip.hover.top="'Edit Details'"
			variant="warning" @click.stop="editDispatch(row.item)" class="mr-1 mt-1">
			<i class="fa fa-pencil"></i>
		</b-button>

		<b-button v-show="status === 'Draft' && !isViewer" size="sm" v-b-modal.deploy-dispatch
			v-b-tooltip.hover.top="'Deploy Dispatch'" variant="success" @click.stop="updateDispatch(row.item)"
			class="mr-1 mt-1">
			<i class="fa fa-share"></i>
		</b-button>

		<b-button v-show="isAllowedToCancel()" size="sm" v-b-modal.cancel-dispatch v-b-tooltip.hover.top="'Cancel Dispatch'"
			variant="danger" @click.stop="updateDispatch(row.item)" class="mr-1 mt-1">
			<em class="fa fa-ban"></em>
		</b-button>

		<b-button v-show="isAllowedToReceive(row.item)" size="sm" v-b-modal.receive-dispatch
			v-b-tooltip.hover.top="'Receive Dispatch'" variant="success" @click.stop="receiveDispatch(row.item)"
			class="mr-1 mt-1">
            <i class="fa fa-check"></i>
		</b-button>

		<b-button v-show="isAllowedToPushPull(row.item)" size="sm" v-b-tooltip.hover.top="'Push Pull Dispatch'"
			variant="success" class="mr-1 mt-1" @click.stop="updateDispatch(row.item)" v-b-modal.push-pull>
			<em class="fa fa-upload"></em>
		</b-button>

		<b-button size="sm" v-b-modal.print-dispatch-summary v-b-tooltip.hover.top="'Print Dispatch Summary'"
			variant="primary" @click.stop="printDispatch(row.item)" class="mr-1 mt-1">
			<em class="fa fa-print"></em>
		</b-button>

	</div>
</template>

<script>
// Util
import { UserUtil } from '@/utils/userutil';

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'dispatch-row-actions',
	props: {
		row: {
			type: Object,
			required: true,
		},
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		isManager: {
			type: Boolean,
			required: true,
		},
		isSupervisor: {
			type: Boolean,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true,
		},
		parentComponent: {
			type: String,
			require: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			loggedUserCompany: this.$store.getters.loggedUserCompany,
		};
	},
	computed: {
		status() {
			return this.row && this.row.item.status;
		},
		destinationCompanyId() {
			return this.row && this.row.item.destination.companyId;
		},
		sourceCompanyId() {
			return this.row && this.row.item.source.companyId;
		},
		currCompanyId() {
			return this.loggedUserCompany.id;
		},
	},
	methods: {
		editDispatch(item) {
			// init parameters
			this.params = this.$store.getters.dispatchParams;
			this.params.selDispatch = item;

			this.$store.dispatch('setDispatchParams', this.params);
			this.$store.dispatch('setCurrentDispatch', item);

			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-edit-dispatch' });
			} else if (!this.isSuperAdmin) {
				this.$router.push({ path: '/edit-dispatch' });
			} else {
				this.$toaster.warning('Invalid Source Screen');
			}
		},
		printDispatch(item) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onPrintDispatch', item);
		},
		updateDispatch(item) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onUpdateDispatch', item);
		},
		receiveDispatch(item) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onReceiveDispatch', { item: item });
		},
		isAllowedToCancel() {
			return (this.status === 'Draft' || this.status === 'In-Transit')
				&& (!this.isViewer && (this.isSuperAdmin || this.isManager || this.isSupervisor))
				&& this.parentComponent === 'Dispatch';
		},
		isAllowedToReceive(dispatch) {
			return (this.status === 'In-Transit' || this.status === 'Receiving')
				&& (this.destinationCompanyId === this.currCompanyId
					|| (this.sourceCompanyId === this.currCompanyId && dispatch.toInactiveNode === 'true')
					|| (this.hasCompanyAccess(this.sourceCompanyId, this.destinationCompanyId)))
				&& !this.isViewer && this.parentComponent === 'Receipt';
		},
		isAllowedToPushPull(dispatch) {
			return this.status === 'Received' && !dispatch.donePushPull
				&& (this.destinationCompanyId === this.currCompanyId || this.sourceCompanyId === this.currCompanyId)
				&& !this.isViewer && this.parentComponent === 'Receipt';
		},
		hasCompanyAccess(sourceCompanyId, destinationCompanyId) {
			return UserUtil.hasCompanyAccess(this.allCompaniesObj, sourceCompanyId) || UserUtil.hasCompanyAccess(this.allCompaniesObj, destinationCompanyId);
		}
	},
};
</script>